@debug: true;

@font-family-base: 'Karla', 'Helvetica', 'Arial', sans-serif;

@brand-primary: #000000;

@red-text: #B51414;

@dark-gray: #000000;
@light-gray: #E4E5E4;

@mid-gray: #A7A7A8;
@dark-text: #4F4F51;
@mud: #4F4F51;

/* Bootstrap modal overlay bg */
@modal-backdrop-bg: white;

@navbar-height: 32px;
