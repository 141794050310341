// out: snackbar.css
#snackbar-container {
    position: fixed;
    left: 20px;
    bottom: 0;
    z-index: 99999;
    text-transform: uppercase;
}
.snackbar {
    overflow: hidden;
    clear: both;
    min-width: 288px;
    max-width: 568px;
    cursor: pointer;
    opacity: 0;
    background-color: @dark-gray!important;
    border: 1px solid white;
}

.snackbar.snackbar-opened {
    height: auto;
    opacity: 1;
}

@media (max-width: 767px) {
    #snackbar-container {
        left: 0px !important;
        right: 0px;
        width: 100%;
        .snackbar {
            min-width: 100%;
        }
        [class="snackbar snackbar-opened"] ~ .snackbar.toast {
            margin-top: 20px;
        }
        [class="snackbar snackbar-opened"] {
            border-radius: 0;
            margin-bottom: 0;
        }
    }
}
