.mc-padding-left-5{
  padding-left: 5px;
}

.mc-padding-left-10{
  padding-left: 10px;
}

.mc-padding-left-15{
  padding-left: 15px;
}

.mc-padding-top-5{
  padding-top: 5px;
}

.mc-padding-top-10{
  padding-top: 10px;
}

.mc-padding-top-15{
  padding-top: 15px;
}

.mc-padding-bottom-5{
  padding-bottom: 5px;
}

.mc-padding-bottom-10{
  padding-bottom: 10px;
}

.mc-padding-bottom-15{
  padding-bottom: 15px;
}

.mc-padding-left-5{
  padding-left: 5px;
}

.mc-padding-left-10{
  padding-left: 10px;
}

.mc-padding-left-15{
  padding-left: 15px;
}

.mc-padding-right-5{
  padding-right: 5px;
}

.mc-padding-right-10{
  padding-right: 10px;
}

.mc-padding-right-15{
  padding-right: 15px;
}

.mc-margin-top-2line{
  margin-top: 2em;
}

.mc-margin-top-3line {
  margin-top: 3em;
}

.mc-margin-top-line{
  margin-top: 1em;
}

.mc-margin-bottom-line{
  margin-bottom: 1em;
}

.mc-margin-top-5{
  margin-top: 5px;
}

.mc-margin-top-10{
  margin-top: 10px;
}

.mc-margin-top-15{
  margin-top: 15px;
}

.mc-margin-top-20{
  margin-top: 20px;
}

.mc-margin-top-25{
  margin-top: 25px;
}

.mc-margin-top-30{
  margin-top: 30px;
}

.mc-margin-bottom-pull-5{
  margin-bottom: -5px;
}

.mc-margin-bottom-pull-10{
  margin-bottom: -10px;
}

.mc-margin-bottom-pull-15{
  margin-bottom: -15px;
}

.mc-margin-bottom-5{
  margin-bottom: 5px;
}

.mc-margin-bottom-10{
  margin-bottom: 10px;
}

.mc-margin-bottom-15{
  margin-bottom: 15px;
}

.mc-margin-bottom-20{
  margin-bottom: 20px;
}

.mc-margin-bottom-25{
  margin-bottom: 25px;
}

.mc-margin-bottom-30{
  margin-bottom: 30px;
}

.mc-margin-left-5{
  margin-left: 5px;
}

.mc-margin-left-10{
  margin-left: 10px;
}

.mc-margin-left-15{
  margin-left: 15px;
}

.mc-margin-left-20{
  margin-left: 20px;
}

.mc-margin-left-25{
  margin-left: 25px;
}

.mc-margin-left-30{
  margin-left: 30px;
}

.mc-margin-right-5{
  margin-right: 5px;
}

.mc-margin-right-10{
  margin-right: 10px;
}

.mc-margin-right-15{
  margin-right: 15px;
}

.mc-margin-right-20{
  margin-right: 20px;
}

.mc-margin-right-25{
  margin-right: 25px;
}

.mc-margin-right-30{
  margin-right: 30px;
}

.mc-bottom-bordered{
  border-bottom: 1px solid @light-gray;
}

.mc-top-bordered{
  border-top: 1px solid @light-gray;
}

.mc-left-bordered{
  border-left: 1px solid @light-gray;
}

.mc-right-bordered{
  border-right: 1px solid @light-gray;
}


.mc-sticky-bottom{
  position: absolute;
  bottom: 0;
}

.mc-no-padding{
  padding: 0 !important;
}

.mc-row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.mc-list-spacer:before{
  content: "\00a0";
}

.mc-strong {
  font-weight:bold;
}

.mc-strong-900{
  font-weight: 900;
}

.mc-lighter{
  font-weight: 200;
}

.mc-condensed-1{
  letter-spacing: -1px;
}

.mc-status-normal{

}

.mc-status-success{
  display: none;

}

.mc-status-error{
  display: none;
}


/* Bootstrap additions */

.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.text-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

@media (min-width: @screen-sm-min) {
  .text-sm-left { text-align: left; }
  .text-sm-right { text-align: right; }
  .text-sm-center { text-align: center; }
  .text-sm-justify { text-align: justify; }
}

@media (min-width: @screen-md-min) {
  .text-md-left { text-align: left; }
  .text-md-right { text-align: right; }
  .text-md-center { text-align: center; }
  .text-md-justify { text-align: justify; }
}

@media (min-width: @screen-lg-min) {
  .text-lg-left { text-align: left; }
  .text-lg-right { text-align: right; }
  .text-lg-center { text-align: center; }
  .text-lg-justify { text-align: justify; }
}
