// out: ../themes-css/material.css

.snackbar {
    // Style
    background-color: #000;
    color: #FFFFFF;
    font-size: 17px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    // Animation
    height: 0;
    -moz-transition: -moz-transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
    -webkit-transition: -webkit-transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
    -moz-transform: translateY(200%);
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
}

.snackbar.snackbar-opened {
    // Style
    padding: 14px 15px;
    margin-bottom: 20px;

    // Animation
    height: auto;
    -moz-transition: -moz-transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s;
    -webkit-transition: -webkit-transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s, height 0s linear 0.2s;
    -moz-transform: none;
    -webkit-transform: none;
    transform: none;
}

